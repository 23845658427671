<template>
  <div id="time-line">
    <div class="timeline">
      <CardHeader
        ref="header"
        :node="headerTitle"
        :numberNoti="numberNotification"
        @editTimeline="editTimeline"
      />
      <div id="content-scroll" class="content-scroll">
        <TimelineFilter v-model="offerTab" @choose-filter="changeTab($event)" />
        <template v-if="isLoading">
          <AppLoading />
        </template>
        <template v-else>
          <div class="timeline__content">
            <div v-for="(tweet, key) of firebaseListTweet" :key="key">
              <TimelineNew
                :propsNew="tweet"
                :propsTab="offerTab"
                :tabindex="key"
                :propsIndex="key"
                :propsLength="firebaseListTweet.length"
                :propsScore="
                  listScores[tweet.userId.id]
                    ? listScores[tweet.userId.id]
                    : null
                "
                @editTweet="editTweet"
                @handleBlock="blockUser($event)"
                @hideTweet="hideTweet"
                @like="like"
              />
            </div>
            <scroll-loader :loader-method="getList" :loader-disable="disable">
            </scroll-loader>
          </div>
        </template>
      </div>
      <TimelinePostModal
        ref="timelinePost"
        :name="tweetEdit ? '投稿を編集する' : '投稿を作成'"
        @close-popup="resetTweetContent"
      >
        <TimelinePost
          @close-popup="$refs['timelinePost'].closeModal()"
          :propsTweet="tweetEdit"
        ></TimelinePost>
      </TimelinePostModal>
      <CardFooter ref="footer" prefer="timeline" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CardFooter from "@/components/CardFooter.vue";
import CardHeader from "@/components/CardHeader.vue";
import TimelineFilter from "@/views/Common/Timeline/TimelineFilter.vue";
import TimelineNew from "@/views/Common/Timeline/TimelineNew.vue";
import TimelinePost from "@/views/Common/Timeline/TimelinePost.vue";
import TimelinePostModal from "@/views/Common/Timeline/TimelinePostModal.vue";
export default {
  name: "Timeline",
  components: {
    CardHeader,
    CardFooter,
    TimelineFilter,
    TimelineNew,
    TimelinePost,
    TimelinePostModal
  },
  metaInfo() {
    return {
      title: "つぶやき",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      disable: false,
      isShowSlider: true,
      offerTab: 1, // 1: すべて; 2: お気に入り
      headerTitle: {
        text: "つぶやき",
        isShowTitle: true,
        isShowLogo: false,
        isTimeLine: true
      },
      tweetEdit: null,
      numberNotification: 0
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "common/isLoading",
      firebaseListTweet: "firebase/listTweet",
      authUser: "auth/user",
      listUserIdsBlock: "auth/userIdsBlock",
      listUserIdsFavorite: "auth/userIdsFavorite",
      listScores: "auth/listScores"
    })
  },
  async created() {
    this.$store.dispatch("common/setIsLoading", false);
    this.getNumberNotification();
    this.$store.dispatch("auth/getListScores");
    await this.getUserIdsBlock();
    await this.getUserIdsFavorite();
    await this.setTypeTweet(this.offerTab);
    await this.getListTweet({
      userId: this.authUser.user_id,
      sex: this.authUser.sex === 1 ? 2 : 1,
      type: this.offerTab,
      listIdsBlock: this.listUserIdsBlock,
      listIdsFavorite: this.listUserIdsFavorite
    });
  },
  watch: {
    firebaseListTweet(newVal, oldVal) {
      if (newVal.length - oldVal.length < 10) {
        this.disable = true;
      } else {
        this.disable = false;
      }
    }
  },
  methods: {
    ...mapActions("firebase", [
      "getListTweet",
      "resetListTweet",
      "setTypeTweet",
      "hiddenUserBlocked",
      "likeTweet"
    ]),
    ...mapActions("auth", ["getUserIdsBlock", "getUserIdsFavorite"]),

    getList() {
      if (this.firebaseListTweet[this.firebaseListTweet.length - 1]) {
        this.getListTweet({
          userId: this.authUser.user_id,
          lastTweet: this.firebaseListTweet[this.firebaseListTweet.length - 1]
            .id,
          sex: this.authUser.sex === 1 ? 2 : 1,
          type: this.offerTab,
          listIdsBlock: this.listUserIdsBlock,
          listIdsFavorite: this.listUserIdsFavorite
        });
      }
    },
    async like(e) {
      let action = "like";
      if (e.is_like) {
        action = "unlike";
      }
      await this.likeTweet({
        userId: this.authUser.user_id,
        tweetId: e.id,
        action: action
      });
      await this.getListTweet({
        userId: this.authUser.user_id,
        lastTweet: this.firebaseListTweet[this.firebaseListTweet.length - 1].id,
        sex: this.authUser.sex === 1 ? 2 : 1,
        type: this.offerTab,
        listIdsBlock: this.listUserIdsBlock,
        listIdsFavorite: this.listUserIdsFavorite,
        like: true
      });
    },
    editTweet(data) {
      this.tweetEdit = data;
      this.$refs["timelinePost"].openModal();
    },
    editTimeline() {
      this.$refs["timelinePost"].openModal();
    },
    async resetTweetContent() {
      this.tweetEdit = null;
      await this.getListTweet({
        userId: this.authUser.user_id,
        sex: this.authUser.sex === 1 ? 2 : 1,
        type: this.offerTab,
        listIdsBlock: this.listUserIdsBlock,
        listIdsFavorite: this.listUserIdsFavorite,
        like: true
      });
    },
    async changeTab(tabId) {
      this.$store.dispatch("common/setIsLoading", true);
      this.offerTab = tabId;
      await this.setTypeTweet(this.offerTab);
      await this.resetListTweet();
      await this.getListTweet({
        userId: this.authUser.user_id,
        sex: this.authUser.sex === 1 ? 2 : 1,
        type: this.offerTab,
        listIdsBlock: this.listUserIdsBlock,
        listIdsFavorite: this.listUserIdsFavorite
      });
      if (document.getElementById("content-scroll")) {
        document.getElementById("content-scroll").scrollTop = 0;
      }
      this.$store.dispatch("common/setIsLoading", false);
    },
    async getNumberNotification() {
      const params = {
        page: 1,
        type: 2,
        perpage: 5
      };
      await this.$store
        .dispatch("userApp/getListUsers", params)
        .then(response => {
          this.numberNotification = response.data.numberNotiUnread;
        });
    },
    blockUser(userId) {
      this.getUserIdsBlock();
      this.hiddenUserBlocked(userId);
    },

    async hideTweet() {
      await this.getListTweet({
        userId: this.authUser.user_id,
        lastTweet: this.firebaseListTweet[this.firebaseListTweet.length - 1].id,
        sex: this.authUser.sex === 1 ? 2 : 1,
        type: this.offerTab,
        listIdsBlock: this.listUserIdsBlock,
        listIdsFavorite: this.listUserIdsFavorite,
        like: true
      });
    }
    // pre() {
    //   document.getElementById("pickup-user").scrollLeft -= 200;
    //   if (document.getElementById("pickup-user").scrollLeft - 200 <= 0) {
    //     this.isShowSlider = true;
    //   }
    // },
    // scroll() {
    //   document.getElementById("pickup-user").scrollLeft += 200;
    //   if (
    //     document.getElementById("pickup-user").scrollLeft +
    //       document.getElementById("pickup-user").clientWidth +
    //       200 >=
    //     document.getElementById("pickup-user").scrollWidth
    //   ) {
    //     this.isShowSlider = false;
    //   }
    // },
  }
};
</script>

<style lang="scss" scoped>
.icon-slider {
  display: none;
}
.list {
  margin-top: 20px;
  overflow-x: auto;
  display: flex;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .image-info {
    padding-right: 8px;
    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 68px;
      height: 68px;
      border-radius: 100%;
      border: 2px solid #464d77;
      margin-bottom: 5px;
      .avatar {
        width: 60px;
        height: 60px;
        border-radius: 100%;
      }
    }
    span {
      font-family: "Hiragino Kaku Gothic Pro W3";
    }
  }
}
.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  padding: 100px 19px 60px 19px;
  padding-top: 112px !important;
}

.timeline {
  &__content {
    padding: 20px 0;
  }
}

@media screen and (min-width: 1200px) {
  .icon-slider {
    cursor: pointer;
    display: block;
    &-next {
      left: 0px;
      z-index: 1;
      top: 15px;
      transform: rotate(180deg);
    }
    &-pre {
      right: 0;
      z-index: 1;
      top: 15px;
    }
  }
  .list {
    margin-top: 20px;
    .image-info {
      padding-right: 20px;
    }
  }
  .content-scroll {
    // height: calc(100vh - 152px);
    // padding: 0 35px;
    padding: 135px 35px 91px 35px;
    padding-top: 135px !important;
  }
  .timeline {
    &__content {
      padding: 20px 231px;
      padding-bottom: 200px;
    }
  }
}
</style>
