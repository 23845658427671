// Tab in timeline-list
export const timelineTabs = [
  {
    id: 1,
    title: "すべて",
    url: "open"
  },
  {
    id: 2,
    title: "お気に入り",
    url: "designation"
  }
];
