<template>
  <div>
    <div class="image-post text mb-3 f-w6">こちらでよろしいですか？</div>
    <div class="d-flex justify-content-center w-100">
      <div class="summary summary-single">
        <img :src="propsImage.image_url" alt="" class="summary__img" />
      </div>
    </div>
    <div class="d-flex justify-content-center image-post">
      <button @click.prevent="acceptCrop()" class="btn-logout">はい</button>
      <button @click="closeModal()" class="btn-cancel-logout">いいえ</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageProfile",
  props: {
    propsImage: {
      type: Object,
      default: null
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },

    acceptCrop() {
      this.$emit("acceptModal");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_fontFamily.scss";
@import "@/assets/scss/_variables.scss";

.image-post {
  margin-top: 30px;
  &.text {
    font-size: 16px;
  }
}

.btn-logout {
  width: 155px;
  height: 45px;
  color: #ffffff;
  background-color: #464d77;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 20px;
}

.btn-cancel-logout {
  width: 155px;
  height: 45px;
  background-color: #e4e4e4;
  color: #000000;
  font-size: 16px;
  border-radius: 5px;
}

.summary {
  * {
    padding: 0;
    margin: 0;
  }
  cursor: pointer;
  border-radius: 12px;
  position: relative;
  height: 100%;
  max-height: 687px;
  overflow: hidden;
  box-shadow: #26262680 2px 3px 4px;
  &__img {
    width: 100%;
    pointer-events: none;
  }
}

.summary-single {
  width: 100%;
}

@media screen and (min-width: 1080px) {
  .image-post {
    &.text {
      font-size: 18px;
    }
  }
}
</style>
