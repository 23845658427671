<template>
  <div class="app-modal" :class="{ active: show }">
    <transition name="slide-down">
      <div v-if="show" class="app-modal__dialog">
        <div class="d-flex justify-content-center">
          <div
            class="d-flex justify-content-center align-items-center app-modal__dialog__header"
          >
            <button class="btn app-modal__close" @click="closeModal()">
              <img
                :src="require('@/assets/image/close.svg')"
                alt="close popup"
              />
            </button>
            <pre class="f-w6 app-modal__tilte">{{ name }}</pre>
          </div>
        </div>
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ModalLayout",
  props: {
    name: String
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      this.$emit("close-popup");
    },
    openModal() {
      this.show = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_fontFamily.scss";
@import "@/assets/scss/_variables.scss";

.app-modal {
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9000;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;

  &.active {
    opacity: 1;
    visibility: visible;
  }
  &.default {
    .app-modal__dialog {
      width: 375px;
      max-width: 100%;
    }
  }

  &__close {
    outline: none;
    box-shadow: none;
  }

  &__dialog {
    overflow: auto;
    position: fixed;
    &::-webkit-scrollbar {
      display: none;
    }
    & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
    }
    width: 100%;
    max-height: 100vh;
    max-width: 503px;
    background-color: #f5f5f5;
    &__header {
      padding: 9px 0;
      top: 0;
      max-width: 1080px;
      width: 100%;
      background-color: #f5f5f5;
      z-index: 100;
      border-bottom: 1px solid #d9d9d9;
    }
    &--content {
      background-color: #f5f5f5;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      max-width: 1080px;
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
      }
    }
  }

  .app-modal__tilte {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 0;
    color: #000000;
  }

  .app-modal__close {
    position: absolute;
    right: 35px;
    padding: 0;
    img {
      width: 14px;
      display: block;
    }
  }
}

.slide-down {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(-20px);
  }

  &-enter-active,
  &-leave-active {
    transition: ease 0.5s;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (min-width: 1080px) {
  .app-modal {
    &__dialog {
      &__header {
        padding: 20px 0;
        top: 0;
        width: 100%;
        background-color: #f5f5f5;
        z-index: 100;
        border-bottom: 1px solid #d9d9d9;
      }
      .app-modal__tilte {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 0;
        color: #000000;
      }
    }
    .app-modal__tilte {
      font-size: 20px;
      line-height: 26px;
    }
    .app-modal__close {
      img {
        width: 18px;
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .app-modal {
    &__dialog {
      height: 100%;
    }
    .app-modal__close {
      left: 20px;
    }
  }
}
</style>
