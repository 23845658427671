<template>
  <div class="timeline-post text-center" id="new">
    <div class="info d-flex">
      <img
        class="info__img"
        :src="authUser.image_url"
        alt=""
        @click="redirectProfile()"
        style="cursor: pointer"
      />
      <div class="text-left">
        <p class="f-w6 info__p">{{ authUser.nickname }}</p>
        <p class="mb-0 f-w3">
          <span class="info__span">BEST: {{ authUser.best_score }}</span>
          <span class="info__span">AVG: {{ authUser.avg_score }}</span>
        </p>
      </div>
    </div>
    <div class="content">
      <textarea
        class="content__textarea f-w3"
        placeholder="その気持ち、シェアしよう"
        maxlength="144"
        v-model="message"
      ></textarea>
    </div>
    <div class="position-relative" v-if="listImage.length > 0">
      <div class="preview d-flex" id="preview_image">
        <div
          v-for="(image, index) in listImage"
          :key="index"
          class="position-relative preview-image"
          :class="{
            finish: index == listImage.length - 1,
            'preview-image--one': listImage.length == 1
          }"
        >
          <div
            v-if="image.video"
            class="preview__img position-relative"
            :class="{
              'video--one': listImage.length == 1
            }"
          >
            <video
              width="100%"
              height="100%"
              id="video"
              controlslist="nodownload"
              preload="metadata"
              :poster="image.image_url"
              type="video/*"
              controls
              class="video-layout preview__video"
              @play="play($event)"
              autoplay
              playsinline
              muted
            >
              <source :src="image.video" type="video/mp4" />
              <source :src="image.video" type="video/mov" />
            </video>
          </div>
          <div
            v-else
            class="preview__img"
            :class="{
              'preview-image--one': listImage.length == 1
            }"
            v-bind:style="{ backgroundImage: 'url(' + image.image_url + ')' }"
          />
          <div
            class="
              position-absolute
              btn-cancel
              d-flex
              justify-content-center
              align-items-center
            "
            @click="removeImage(index)"
          >
            <img
              :src="require('@/assets/image/timeline/post-cancel.svg')"
              alt="close popup"
            />
          </div>
        </div>
      </div>
      <!-- </carousel> -->
      <div
        v-if="listImage.length > 1"
        class="position-absolute icon-slider icon-slider-pre"
        @click="pre()"
      >
        <img src="@/assets/image/message/pre.svg" alt="" />
      </div>
      <div
        v-if="listImage.length > 1"
        class="position-absolute icon-slider icon-slider-next"
        @click="next()"
      >
        <img src="@/assets/image/message/next.svg" alt="" />
      </div>
      <hr />
    </div>
    <div class="post text-left d-flex flex-column">
      <label class="post__label mb-0" style="cursor: pointer">
        <input
          class="d-none"
          ref="form-file"
          multiple
          v-on:change="chooseImage($event)"
          accept=".jpeg, .png, .jpg, .mov, .mp4, video/*"
          type="file"
          :key="avatar"
        />
        <div class="text-left w-100">
          <img
            class="post__img icon"
            src="@/assets/image/timeline/post-library.svg"
            alt=""
          />
          <span class="post__span f-w3">ライブラリから選択</span>
        </div>
      </label>
      <span class="note-picture f-w3">※複数選択可能</span>
      <label class="post__label camera" v-if="isShowCamera">
        <input
          class="d-none"
          capture="camera"
          ref="form-file"
          v-on:change="chooseImage($event)"
          accept=".jpeg, .png, .jpg, .mov, .mp4, video/*"
          type="file"
        />
        <div class="text-left w-100">
          <img
            class="post__img icon"
            src="@/assets/image/timeline/post-camera.svg"
            alt=""
          />
          <span class="post__span f-w3">写真をとる</span>
        </div>
      </label>
      <label class="post__label camera" v-else>
        <input
          class="d-none"
          ref="form-file"
          v-on:change="chooseImage($event)"
          accept=".jpeg, .png, .jpg, .mov, .mp4, video/*"
          type="file"
        />
        <div class="text-left w-100">
          <img
            class="post__img icon"
            src="@/assets/image/timeline/post-camera.svg"
            alt=""
          />
          <span class="post__span f-w3">写真をとる</span>
        </div>
      </label>
    </div>
    <p v-if="error" class="noti text-left mb-0 f-w3">
      画像は4件以下投稿可能です。
    </p>
    <div class="send">
      <button
        v-if="propsTweet"
        @click="handleSubmit"
        class="send__btn"
        :class="!this.message && this.listImage.length == 0 ? 'disabled' : ''"
      >
        完了
      </button>
      <button
        v-else
        @click="handleSubmit"
        class="send__btn"
        :class="!this.message && this.listImage.length == 0 ? 'disabled' : ''"
      >
        投稿
      </button>
    </div>
    <div class="edit-profile-vue-cropper" v-if="isShowCropper">
      <Cropper
        class="upload-cropper"
        :src="imageTest"
        @change="onChange"
        ref="cropper"
        :stencil-props="{
          aspectRatio: aspectRatio1
        }"
        :stencil-size="stencilSize"
        imageRestriction="stencil"
      />
      <div class="d-flex btn-option justify-content-center">
        <div class="f-w3 mr-3 btn-cancel">
          <button @click="cancelCrop()" class="btn">キャンセル</button>
        </div>
        <div class="f-w3 btn-success">
          <button @click="confirmCrop()" class="btn">登録する</button>
        </div>
      </div>
    </div>
    <TimelineImageModal ref="modal-image">
      <TimelineImage
        :propsImage="propsImage"
        @acceptModal="acceptImage"
        @closeModal="rejectImage"
      />
    </TimelineImageModal>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import TimelineImage from "@/views/Common/Timeline/TimelineImage";
import TimelineImageModal from "@/views/Common/Timeline/TimelineImageModal";
// import { Carousel, Slide } from "vue-carousel";
import { mapGetters, mapActions } from "vuex";
import firebase from "@/firebase";
// import { urlText } from "@/utils/ui";
import { DEFAULT_VIDEO_TIME } from "@/definition/constants";

const storage = firebase.storage;

export default {
  name: "TimelinePost",
  components: {
    Cropper,
    TimelineImage,
    // Carousel,
    // Slide,
    TimelineImageModal
  },
  props: {
    propsTweet: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      propsImage: {
        image_url: null
      },
      isShowCamera: false,
      isShowCropper: false,
      aspectRatio1: 3 / 2,
      minAspectRatio: 330,
      maxAspectRatio: 220,
      listImage: [],
      listImageUrl: [],
      listDeleteImageUrl: [],
      listImageModel: {},
      currentSlide: 0,
      imageTest: null,
      message: "",
      error: false,
      avatar: 0
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user"
    })
  },
  created() {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      window.innerWidth <= 699
    ) {
      this.isShowCamera = true;
    } else {
      this.isShowCamera = false;
    }
    if (this.propsTweet) {
      this.listImage = this.propsTweet.imageUrls.map(imageUrl => {
        return {
          image_url: imageUrl.type === "image" ? imageUrl.url : null,
          file: null,
          video: imageUrl.type === "video" ? imageUrl.url : null
        };
      });
      this.message = this.propsTweet.message;
    }
  },
  methods: {
    ...mapActions("firebase", ["createTweet", "editTweet"]),

    play(e) {
      const video = e.target;
      let videos = document.querySelectorAll(".preview__video");
      videos.forEach(element => {
        if (element !== video) {
          element.pause();
        }
      });
    },
    async handleSubmit() {
      if (this.message || this.listImage.length > 0) {
        this.$root.$refs.loading.start();
        const date = new Date();
        const folderName =
          date.getFullYear() +
          "_" +
          this.formatTime(date.getMonth() + 1) +
          "_" +
          this.formatTime(date.getDate());
        const fileNameTemp =
          "" +
          this.formatTime(date.getHours()) +
          this.formatTime(date.getMinutes()) +
          this.formatTime(date.getSeconds());
        let storageRef = storage.ref();
        let uploadImage = this.listImage.map((image, index) => {
          if (image.file) {
            let fileName = fileNameTemp + index;
            const imgRef = storageRef.child(
              `tweets/${this.authUser.user_id}/${folderName}/${fileName}`
            );
            this.listImageUrl.push({
              name: `tweets/${this.authUser.user_id}/${folderName}/${fileName}`,
              type: image.video ? "video" : "image"
            });
            return imgRef.put(image.file);
          } else {
            this.listImageUrl.push({
              url: image.video ? image.video : image.image_url,
              type: image.video ? "video" : "image"
            });
          }
        });
        await Promise.all(uploadImage);
        if (
          this.listDeleteImageUrl.length > 0 &&
          this.propsTweet.imageUrls.length > 0
        ) {
          let removeImage = this.listDeleteImageUrl.map(imageUrl => {
            let fileRef = storage.refFromURL(imageUrl);
            fileRef.delete();
          });
          await Promise.all(removeImage);
        }

        // this.listImageUrl = this.listImageUrl.map(image => {
        //   if (image.image_url) {
        //     return image.image_url;
        //   }
        //   return storageRef.child(image.name).getDownloadURL();
        // });
        // this.listImageUrl = await Promise.all(this.listImageUrl);
        await Promise.all(
          this.listImageUrl.map(image => {
            if (image.url) {
              return image.url;
            }
            return storageRef.child(image.name).getDownloadURL();
          })
        ).then(res => {
          this.listImageUrl = res.map((url, i) => {
            return {
              url: url,
              type: this.listImageUrl[i].type
            };
          });
        });

        if (!this.propsTweet) {
          let data = {
            userId: this.authUser.user_id.toString(),
            message: this.message,
            imageUrls: this.listImageUrl
          };
          this.createTweet(data);
        } else {
          let data = {
            tweetId: this.propsTweet.id,
            message: this.message,
            imageUrls: this.listImageUrl
          };
          this.editTweet(data);
        }
        this.$emit("close-popup");
        this.$root.$refs.loading.finish();
      }
    },
    redirectProfile() {
      if (this.authUser.sex == 1) {
        this.$router.push({
          name: "ProfileViewMyProfile"
        });
      } else {
        this.$router.push({
          name: "CastMyPageProfile"
        });
      }
    },
    formatTime(time) {
      if (time < 10) {
        time = "0" + time;
      }

      return time;
    },
    next() {
      document.getElementById("preview_image").scrollLeft += 320;
    },
    pre() {
      document.getElementById("preview_image").scrollLeft -= 320;
    },
    removeImage(index) {
      if (this.listImage[index].image_url) {
        this.listDeleteImageUrl.push(this.listImage[index].image_url);
      }
      if (this.listImage[index].video) {
        this.listDeleteImageUrl.push(this.listImage[index].video);
      }
      this.avatar++;
      this.listImage.splice(index, 1);
    },
    getIsVideo() {
      for (let i = 0; i < this.listImage.length; i++) {
        if (this.listImage[i].video) {
          return false;
        }
      }
      return true;
    },
    chooseImage(event) {
      if (this.listImage.length + event.target.files.length > 4) {
        this.error = true;
      } else {
        let form = new FormData();
        form.append("avatar", event.target.files);
        var files = event.target.files;
        if (files.length > 4) {
          this.error = true;
          return;
        }
        let number_video = 0;
        for (var j = 0; j < files.length; j++) {
          if (files[j].type.includes("video/")) {
            number_video++;
          }
        }
        if (number_video >= 2) {
          this.$toast("動画が1件しか指定できません。", "通知", "danger");
          return;
        }
        for (var i = 0; i < files.length; i++) {
          this.$root.$refs.loading.start();
          const vm = this;
          var file = event.target.files[i];
          var fileReader = new FileReader();
          if (file.type.match("image")) {
            fileReader.onload = e => {
              var blob_image = new Blob([e.target.result]);
              var url = URL.createObjectURL(blob_image);
              vm.listImage.push({
                image_url: url,
                file: blob_image,
                video: null
              });
            };
            fileReader.readAsArrayBuffer(files[i]);
          } else if (vm.getIsVideo() && file.type.match("video")) {
            fileReader.onload = function() {
              var blob = new Blob([fileReader.result], { type: file.type });
              var url = URL.createObjectURL(blob);
              var video = document.createElement("video");
              var timeupdate = function() {
                if (snapImage()) {
                  video.removeEventListener("timeupdate", timeupdate);
                  video.pause();
                }
              };
              video.addEventListener("loadeddata", function() {
                if (snapImage()) {
                  video.removeEventListener("timeupdate", timeupdate);
                }
              });
              var snapImage = function() {
                var canvas = document.createElement("canvas");
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                canvas
                  .getContext("2d")
                  .drawImage(video, 0, 0, canvas.width, canvas.height);
                var image = canvas.toDataURL();
                var success = image.length > 100000;
                if (success) {
                  if (video.duration > DEFAULT_VIDEO_TIME) {
                    vm.$toast(
                      "動画は15秒以内のものを指定してください",
                      "通知",
                      "danger"
                    );
                    return success;
                  } else {
                    vm.listImage.push({
                      image_url: image,
                      file: blob,
                      video: url
                    });
                  }
                }
                return success;
              };
              video.addEventListener("timeupdate", timeupdate);
              video.preload = "metadata";
              video.src = url;
              video.muted = true;
              video.playsInline = true;
              video.play();
            };
            fileReader.readAsArrayBuffer(files[i]);
          } else if (!vm.getIsVideo() && file.type.match("video")) {
            this.$toast("動画が1件しか指定できません。", "通知", "danger");
            this.$root.$refs.loading.finish();
            return;
          }
        }
        this.avatar++;
        this.$root.$refs.loading.finish();
      }
    },
    async confirmCrop() {
      const base64Response = await fetch(this.result);
      this.blod = await base64Response.blob();
      let img = await window.URL.createObjectURL(this.blod);
      this.propsImage.image_url = img;
      this.$refs["modal-image"].openModal();
    },
    cancelCrop() {
      this.avatar = null;
      this.isShowCropper = false;
    },
    onChange({ coordinates, canvas }) {
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      this.result = canvas.toDataURL("image/jpeg");
    },
    stencilSize() {
      return {
        width: this.minAspectRatio,
        height: this.maxAspectRatio
      };
    },
    rejectImage() {
      this.$refs["modal-image"].closeModal();
    },
    async acceptImage() {
      const base64Response = await fetch(this.result);
      const blod = await base64Response.blob();
      let img = await window.URL.createObjectURL(blod);
      this.listImageModel.image = null;
      this.listImage.push({
        image_url: img,
        file: blod
      });
      this.$refs["modal-image"].closeModal();
      this.isShowCropper = false;
    }
  }
};
</script>

<style lang="scss" scoped>
#new {
  /deep/.edit-profile-vue-cropper {
    position: fixed;
    top: 0;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 100%;
    z-index: 100;
    background-color: #fff;
    max-width: 1080px;
    .upload-cropper {
      height: calc(100% - 90px);
    }
    .btn-cancel {
      background-color: #dbdbdb;
      border-radius: 5px;
      height: 45px;
      width: 163px;
      margin-right: 11px;
      .btn {
        width: 100%;
        height: 100%;
      }
    }
    .btn-success {
      background-color: #464d77;
      border-radius: 5px;
      height: 45px;
      width: 163px;
      .btn {
        color: #fff;
        width: 100%;
        height: 100%;
      }
    }
    .btn-option {
      background-color: white;
      position: relative;
      z-index: 10;
      padding: 20px 0;
    }
  }
  /deep/ .VueCarousel-dot-container {
    margin-top: 0 !important;
    button {
      margin-top: 0 !important;
    }
  }
}

.note-picture {
  font-size: 10px;
  color: #9c9c9c;
}

.timeline-post {
  overflow: auto;
  height: calc(100% - 40px);
  padding: 20px 19px;
  position: relative;

  .info {
    margin-bottom: 15px;
    &__img {
      height: 47px;
      width: 47px;
      border-radius: 50%;
      margin-right: 11px;
    }
    &__p {
      font-size: 16px;
      margin-bottom: 3px;
    }
    &__span {
      margin-right: 25px;
      font-size: 12px;
    }
  }
  .content {
    margin-bottom: 15px;
    &__textarea {
      width: 100%;
      overflow: unset;
      padding: 12px 18px;
      font-size: 14px;
      height: 90px;
      border-radius: 5px;
      resize: none;
      color: #000000;
    }
  }
  .icon-slider {
    display: none;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 33px;
      height: 33px;
    }
  }
  .icon-slider-next {
    right: 0;
  }
  .icon-slider-pre {
    left: 0;
  }
  .preview {
    overflow-x: scroll;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    &-image {
      width: 300px;
      margin-right: 15px;
      &.finish {
        margin-right: 0;
      }
      &.preview-image--one {
        width: 100%;
      }
    }
    &__img {
      border-radius: 5px;
      width: 300px;
      height: 300px;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      &.preview-image--one {
        width: 100%;
      }
      &.video--one {
        width: 100%;
      }
    }
    .btn-cancel {
      top: 5px;
      right: 5px;
      background-color: #ffffff;
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }
  }
  .post {
    &__label {
      margin-bottom: 16px;
    }
    &__img {
      margin-right: 11px;
      width: 18px;
    }
    &__span {
      color: #404040;
      font-size: 14px;
    }
  }
  .send {
    margin: 16px 0;
    &__btn {
      font-size: 16px;
      background-color: #464d77;
      border-radius: 5px;
      color: #ffffff;
      width: 100%;
      padding: 8px 0;
      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }
}

.noti {
  font-size: 12px;
  color: #ee4e4e;
}

@media screen and (min-width: 1080px) {
  .note-picture {
    font-size: 12px;
  }
  #new {
    /deep/.edit-profile-vue-cropper {
      top: 0;
      .upload-cropper {
        height: calc(100% - 173px);
      }
      .btn-cancel {
        height: 55px;
        width: 210px;
        margin-right: 20px;
        font-size: 20px;
      }
      .btn-success {
        height: 55px;
        width: 210px;
        font-size: 20px;
      }
      .btn-option {
        padding: 35px 0;
      }
    }
  }
  .timeline-post {
    padding: 20px 25px;
    .info {
      &__img {
        height: 55px;
        width: 55px;
        margin-right: 20px;
      }
      &__p {
        margin-bottom: 10px;
      }
    }
    .content {
      &__textarea {
        height: 120px;
        font-size: 16px;
      }
    }
    .preview {
      .btn-cancel {
        width: 21px;
        height: 21px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .camera {
    display: none;
  }
  .timeline-post {
    .icon-slider {
      display: block;
    }
  }
}
</style>
